<template>
  <main class="services-container" v-if="$locale['object']['services-main-banner']">
    <GlobalMainBanner :bannerData="$locale['object']['services-main-banner']" />
    <ServicesInfoBanner />
    <ServicesCards />
    <ServicesHowWeWork />
    <!-- <ServicesValues /> -->
    <AboutSlider />
  </main>
  <Spinner v-else />
</template>

<script>
import ServicesInfoBanner from "./ServicesInfoBanner.vue";
import ServicesCards from "./ServicesCards.vue";
import ServicesHowWeWork from "./ServicesHowWeWork.vue";
import ServicesValues from "./ServicesValues.vue";
import GlobalMainBanner from "../global/GlobalMainBanner.vue";
import AboutSlider from "@/components/about/AboutSlider.vue";

export default {
  components: { ServicesInfoBanner, ServicesCards, ServicesHowWeWork, ServicesValues, GlobalMainBanner, AboutSlider },
  async beforeMount() {
    if (!this.$locale["object"]["services-main-banner"]) await this.$store.dispatch("updateLocal");
  },
};
</script>

<style lang="scss">
.services-container {
  position: relative;
}
</style>
