<template>
  <section class="services">
    <div class="services-container main">
      <div class="services-content">
        <ServicesCard v-for="(card, key, idx) in $locale['object']['services-cards']" :key="idx" :card="card" :isOdd="idx % 2 !== 0" />
      </div>
    </div>
  </section>
</template>

<script>
import ServicesCard from "./ServicesCard.vue";
export default {
  components: { ServicesCard },
};
</script>

<style lang="scss">
.services {
  margin-top: 96px;
  @media (min-width: $tablet_width) {
    &-content {
      margin-top: -185px;
    }
  }
}
</style>
