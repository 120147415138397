<template>
  <div class="services-card">
    <div :class="['services-card-content', isOdd ? 'isOdd' : '']">
      <img class="services-card-image" :src="card.image" :alt="card.title" id="changeLight" ref="changeLight" />
      <div class="services-card-info" id="changeText" ref="changeText">
        <h3 class="services-card-title special-title">{{ card.title }}</h3>
        <p class="services-card-description">{{ card.desc }}</p>
        <!-- <button class="services-card-button">
          {{ $locale["services-button"] }}
          <iconic class="services-card-icon" name="circle_plus" />
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "isOdd"],
  methods: {
    loadImage(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible ? target.classList.add("active") : target.classList.remove("active");
    },
    loadText(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible && target.classList.add("activeText");
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.loadImage, { threshold: 0.3 });
    const observerText = new IntersectionObserver(this.loadText, { threshold: 0.6 });
    observer.observe(this.$refs.changeLight);
    observerText.observe(this.$refs.changeText);
  },
};
</script>

<style lang="scss">
.services-card {
  position: relative;
  &:nth-child(1),
  &:nth-child(5) {
    &::before {
      background: $tundora;
    }
  }
  &:nth-child(even) {
    .services-card-image {
      transform: scale(0.9) translateX(50%);
      opacity: 0;
      transition: 1.2s ease-in-out all;
      &.active {
        opacity: 1;
        transform: scale(1) translateX(0);
      }
    }
  }
  &:nth-child(2) {
    &::before {
      background: $dove_gray;
    }
  }
  &::before {
    content: "";
    left: -50vw;
    right: -50vw;
    height: calc(100% + 78px);
    bottom: calc(-50% - 78px);
    position: absolute;
    z-index: -1;
  }
  &-content {
    position: relative;
    width: 90%;
    max-width: 321px;
    height: 314px;
    color: white;
    border-radius: 12px;
    margin: 0 auto 78px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
  }
  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 12px;
    transform: scale(0.9) translateX(-50%);
    opacity: 0;
    transition: 1.2s ease-in-out all;
    &.active {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
  &-button {
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: $sec_font;
    font-size: 12px;
    background: none;
    color: white;
    outline: none;
    border: none;
    align-self: flex-end;
  }
  &-icon {
    svg {
      width: 27px;
      height: 27px;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    padding: 0 20px 12px;
    background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.65) 46.64%, rgba(0, 0, 0, 0.92) 100%);
    background-blend-mode: multiply;
    border-radius: 12px;
    height: 100%;
    justify-content: flex-end;
    transform: scale(0.9) translateY(-50%);
    opacity: 0;
    transition: 1.2s ease-in-out all;
    &.activeText {
      opacity: 1;
      transform: scale(0.9) translateY(0);
    }
  }
  &-title {
    font-size: 20px;
  }
  &-description {
    font-family: $font_text;
    font-size: 15px;
    margin-bottom: 8px;
  }
  @media (min-width: $tablet_width) {
    .isOdd {
      flex-direction: row-reverse;
    }
    &::before {
      bottom: calc(-27% - 78px);
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6) {
      .services-card-content {
        color: $shark;
      }
      .services-card-button {
        color: $shark;
      }
    }
    &-content {
      gap: 15px;
      background: none;
      flex-direction: row;
      max-width: 712px;
    }
    &-image {
      position: static;
      width: 366px;
    }
    &-info {
      background: none;
      align-self: flex-end;
    }
  }
  @media (min-width: $desktop_width) {
    padding-top: 17px;
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5) {
      &::before {
        background: $tundora;
      }
    }
    &:nth-child(2) {
      &::before {
        background: none;
      }
    }
    .isOdd {
      color: $shark;
      button {
        color: $shark;
      }
    }
    .services-card {
      &-content {
        max-width: 880px;
        gap: 18px;
        width: 100%;
        &:not(.isOdd) {
          color: white;
          button {
            color: white;
          }
        }
      }
    }
    &-title {
      font-size: 24px;
    }
    &-description {
      font-size: 16px;
      margin-bottom: 30px;
    }
    &-button {
      font-size: 14px;
      font-weight: 200;
      margin-right: 20px;
    }
    &-image {
      width: 515px;
    }
  }
  @media (min-width: $tv_width) {
    .services-card {
      &-description {
        line-height: 24px;
      }
      &-content {
        max-width: 1200px;
        gap: 50px;
      }
    }
  }
}
</style>
